import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service to provide environment specific information for login and logout.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthEnvService {
  /**
   * BehaviorSubject for the pre-login URL
   * - Provided by Guard's router state snapshot
   */
  public preLoginUrl$: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);
}
